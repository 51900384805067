@import 'variables';

@media (min-width: 1199px) {
	#search-button-container {
		padding-left: 5px;
		padding-right: 5px;
	}
}
@media (min-width: 992px) {
	#contact-us {
		.btn {
			max-width: 256px;
		}
	}
	#search-button {
		&.price-alert-handler-checked {
			font-size: 18px !important;
		}
	}
}

@media (max-width: 442px) {
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
	}
	#cookie-notice {
		.description {
			font-size: 13px !important;
			line-height: 1.5 !important;
		}

		img {
			width: 16px !important;
			height: 16px !important;
			margin: 0 5px;
		}
	}
}

// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {
	#image-curve {
		clip-path: unset;
		background-image: url('/images/flights/tablet.webp');
		align-items: unset;
		padding: 25px;
		height: 320px;
		margin-top: 60px;

		.banner-text {
			font-size: 30px;
			line-height: 1.5;
		}
	}
	.input-items {
		margin: 6px 0;

		.form-control,
		.input-control {
			background: #fff;
		}
		label {
			margin: 4px 0;
		}
	}
	#kayak-compare-filter-form {
		.form-check-label {
			color: #000;
		}
		.main-inputs {
			.form-control.cs-input {
				background: #eee;
			}
		}
		.input-items {
			.form-control,
			.input-control {
				background: #eee;
			}
		}
	}

	#cookie-notice .description,
	.cookie-confirm-btn {
		font-size: 13px;
	}
	#newsletter-form {
		h4,
		h5 {
			font-size: 24px;
		}
	}
	.main-inputs {
		//display: unse/t !important;
		& > div {
			.input-box {
				margin: 0 !important;
			}
		}
	}
	.input-group {
		background-color: unset !important;
		border-radius: 4px;
	}
	.flex-one-desktop {
		display: unset !important;
	}

	.sign-up-button {
		font-size: 18px;
		font-weight: 600;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: #fff;
	}
	#search-button-container {
		#search-button {
			min-height: 50px;
			font-size: 22px;
		}
	}

	#slogan-container {
		padding: 50px 0;

		&:before {
			display: none;
		}
	}

	#filter-form {
		.inline-inputs {
			.input {
				width: 100%;
			}
		}
	}
	.input-control:not(.filled) i {
		color: #6d6d6d;
	}
	.basic-drop-down {
		margin-right: 0;

		.body {
			max-width: unset !important;
		}
	}
	#companies {
		margin-top: 25px;
		margin-bottom: 25px;

		ul {
		}
	}
	#search-button {
		box-shadow: unset !important;
	}
	.flights-filter-form {
		margin-top: 12px;
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	#newsletter-form {
		text-align: center;
	}
	.input-items {
		.form-control,
		.input-control {
			background: #fff;
		}
	}
	footer #footer-links li,
	footer p {
		font-size: 18px;
	}

	#slogan-container .body .slogan {
		font-size: 30px;
	}
	#progress-loading {
		#img-area {
			margin: 20px 0;
		}

		.body {
			justify-content: flex-start;
			padding-top: 80px;
			p {
				font-size: 22px;
			}
		}
		#search-icon {
			width: 85px;
			height: 85px;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 45px;
		}
	}
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {
	.phone-call-section {
		display: flex;
	}
	.navbar {
		min-height: 62px;
	}
	#filter-form #date-to .date-picker-range {
		right: 0;
	}
	#image-curve {
		background-image: url('/images/flights/mobile.webp');
		align-items: unset;
		padding: 25px;
		height: 300px;

		.banner-text {
			font-size: 24px;
			line-height: 32px;
		}
	}

	#main-area {
		.title {
			margin-top: 25px;
		}
	}
	#footer-links {
		display: unset !important;
	}

	.input-box .input-control .selected,
	.selected-time,
	.basic-drop-down .d-name {
		font-size: 15px;
		line-height: 24px;
	}
	.input-items label {
		font-size: 14px;
		line-height: 24px;
		color: #fff;
	}

	.input-items {
		.form-control,
		.input-control {
			background: #fff;
		}
	}

	#passthru-p3 {
		.title {
			font-size: 35px;
		}
	}
	#flights-filter-form #date-to,
	#check-out-date {
		.date-picker-range {
			right: 0;
		}
	}
	#mobile-input-passengers .body {
		right: 0;
		left: unset;
	}
	footer #footer-links li {
		display: block;
		border-right-width: 0px;
		margin: 16px 0;
	}
	#cookie-notice {
		padding: 6px;

		.description {
			font-size: 15px;
			line-height: 1.7;
		}

		img {
			width: 17px;
			height: 17px;
			margin: 0 5px;
		}
	}
	#credential_picker_iframe {
		bottom: 65px !important;
	}

	#kayak-compare-filter-form {
		.input-group .input-box input {
			background-color: #fff;
		}
		#parent-filter {
			background-color: transparent;
		}

		.search-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 1000;
			display: flex;
			justify-content: center;
			align-items: center;
			background-image: linear-gradient(180deg, rgba(0, 26, 62, 0.7) 0%, rgba(0, 59, 138, 0.7) 100%);
		}

		.flight-info {
			color: #fff;
			&__data {
				display: flex;
				justify-content: space-between;
				padding-bottom: 16px;
				border-bottom: 1px solid #263d5b;
			}

			&__day {
				font-size: 12px;
			}
			&__location {
				font-size: 20px;
				font-weight: 800;
				margin-bottom: 16px;
			}

			&__pricing-type,
			&__date,
			&__travelers {
				font-size: 14px;
				font-weight: 700;
				text-transform: capitalize;
			}
		}
		.company-item {
			&--white-bg {
				background-color: #fff;
				border-radius: 8px;
				padding: 6px;
				border: 2px solid transparent;
			}

			&--selected {
				border-color: $yellow;
			}
		}
		.brands-section {
			&.brands-selection {
				background: transparent;
			}
		}

		.inline-inputs {
			.input {
				width: 100%;
			}
		}
	}
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
	.basic-drop-down .d-name,
	.basic-drop-down .d-icon i {
		color: #000;
	}
	.basic-drop-down {
		& > div {
			background: #eeeeee;
			border-radius: 8px;
			padding: 8px;
			display: inline;
		}
	}
	footer {
		p {
			font-size: 14px;
		}
	}
	#main-content {
		#title {
			font-size: 32px;
		}
		#description {
			// font-size: 18px;
			text-align: justify;
		}
	}
	#main-area {
		.hotels-filter-form {
			background-color: $blue !important;
			.title {
				margin: -15px;
				padding: 15px;
				margin-bottom: 0px;
				//background-color: #fff;
			}
		}
	}
	.select-box {
		margin: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 5px;

		margin-bottom: 5px;

		.selected-area {
			min-width: 100px;
			min-height: 35px;
		}
	}

	.room-name-area {
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: space-between;

		.remove-icon-mobile {
			display: block;
		}
	}
	#mobile-input-passengers .body {
		width: calc(200% + 30px);
	}

	#companies {
		ul {
			li {
				width: 145px;

				div img {
					width: 120px;
				}
			}
		}
	}
	#slogan-container .body .slogan {
		font-size: 25px !important;
	}
	#passthru-p3 {
		.title {
			font-size: 25px;
		}
		.logo {
			width: 106px !important;
		}
		.brand-redirection {
			padding: 5px;
			margin-top: 5px;
		}
		#loading-to-partner #animation .m_scroll_arrows {
			width: 12px;
			height: 12px;
		}
	}
}

@media (min-width: 1200px) {
	.top-inputs {
		padding-left: 7px;
	}

	.main-inputs {
		& > div {
			padding: 0;
		}
	}
}

@media (max-width: 575px) {
	#main-area {
		.title {
			font-size: 27px !important;
			padding: 10px;
			margin: 10px;
			//background-color: #fff;
		}
	}
}
@media (max-width: 360px) {
	.Logo {
		width: 160px;
	}
}
